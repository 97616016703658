export default function() {

	var closedClassName = 'is-closed';


	function attachTriggerEvents() {
		window.site.body.off('click', '.js-accordion-trigger').on('click', '.js-accordion-trigger', function() {
			toggleAccordionContent($(this));
			return false;
		});

		window.site.body.off('click', '.js-accordion-sub-trigger').on('click', '.js-accordion-sub-trigger', function() {
			toggleAccordionContent($(this).closest('.js-accordion-trigger'));
			return false;
		});
	}

	function toggleAccordionContent(triggerElement) {
		var target = $(triggerElement.attr('data-accordion-target'));
		target.stop().slideToggle('fast').toggleClass(closedClassName).attr('aria-hidden', target.hasClass(closedClassName) ? 'true' : 'false');
		triggerElement.toggleClass(closedClassName);
		triggerElement.find('.js-accordion-sub-trigger').attr('aria-expanded', triggerElement.hasClass(closedClassName) ? 'false' : 'true');
	}

	const initNewAccordion = () => {
		$('.st-accord').on('click', function() {
			$(this).toggleClass("st-accord--active");
			const panel = $($(this).attr('data-accordion-target'));

			if (panel.css("display") === "block") {
				panel.css("display", "none");
			} else {
				panel.css("display", "block");
			}
		})
	}


	initNewAccordion();
	if (!$('.js-accordion-trigger').length) {
		return;
	}
	attachTriggerEvents();


}
